
import swal from "sweetalert2";

export const alertar = (type, titulo, texto, icone) => {

    if (type === "basic") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-primary",
        },
      });
    } else if (type === "info") {
      swal.fire({
        icon: "info",
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-info",
        },
      });
    } else if (type === "success") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-success",
        },
        icon: "success",
      });
    } else if (type === "warning") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-warning",
        },
        icon: "warning",
      });
    } else if (type === "question") {
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-default",
        },
        icon: "question",
      });
    }else if(type === 'moment'){
      swal.fire({
        position: 'top-end',
        icon: icone,
        title: `${titulo}`,
        showConfirmButton: false,
        timer: 1500
      })
    }else if(type === 'errorAutenticacaoLogin'){
      swal.fire({
        icon: icone,
        title: `${titulo}`,
        showConfirmButton: false,
        timer: 1500
      });
    }else if(type === 'successchangepassword'){
      swal.fire({
        title: `${titulo}`,
        text: `${texto}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "sweet-btn-success",
        },
        icon: "success",
      }).then(result => {
        if (result.isConfirmed) {
          window.location.href="/login";
        }
      })
    }
}


export const notificarChrome = async (dados) => {

  if("Notification" in window === true){

      await Notification.requestPermission();
      let title = dados.titulo
      let body = dados.texto
      // let icon = "url"
      let noti = new Notification(title,{body})
      noti.close()

  }else{
    alert('Ative a permissão de notificação do seu navegador.')
  }

}